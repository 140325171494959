import React, { useContext, useState, Fragment, useEffect } from 'react';
import { Card, CardBody, CardFooter, CardHeader, Form, Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import ConfirmAddress from './ConfirmAddress';
import Acknowledge from './Acknowledge';
import ChooseDateForm from './ChooseDateForm';
import Success from './Success';
import AppContext, { AuthWizardContext } from '../../../../context/Context';
import Loader from '../../../../components/common/Loader';
import { Row } from 'reactstrap';

import WizardModal from './WizardModal';
import ButtonIcon from '../../../../components/common/ButtonIcon';

const officeRenoId = 821993;
const officeSlcId = 75554;

const UserForm = ({guid}) => {
  const { isRTL } = useContext(AppContext);
  const { user, setUser, step, setStep, setDefaults, officePhone, setOfficePhone, customerSettings, db, testData } = useContext(AuthWizardContext);
  const { register, handleSubmit, errors, watch, reset, clearError } = useForm();
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [scheduled, setScheduled] = useState(false);
  const [custData, setCustData] = useState({});
  const [cardTop, setCardTop] = useState(null);
  const [jobPathRoot, setJobPathRoot] = useState(null);
  const [once, setOnce] = useState(false);

  const watchAllFields = watch()
  useEffect(() => {
//    console.log(watchAllFields);
  }, [watchAllFields]);

  useEffect(() => {
    if (customerSettings) {
      console.log(`Endpoint: ${customerSettings.endpoint}`);
      setJobPathRoot(customerSettings.endpoint);
    }
  }, [setJobPathRoot, customerSettings]);

  const onSubmitData = data => {
    setUser({ ...user, ...data });
    console.log("onSubmit");
    const values = {...custData, ...user, ...data};
    if (!values.newCabinets) {
      values.acknowledgeNewCabinets = false;
    }
    if (!values.existingCabinets) {
      values.tearout = '';
    }
    console.log(values);
    console.log(data);
    reset(values);
    setStep(step + 1);
  };

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const handleBackStep = targetStep => {
    if (step !== 4) {
      if (targetStep < step) {
        setStep(targetStep);
      }
    } else {
      toggle();
    }
  };

  useEffect(() => {
    console.log("useEffect reset");
    reset(custData);
  },[reset, custData]);

  useEffect(() => {
    console.log(`useEffect ${db} ${guid} ${jobPathRoot}`);
    async function getData() {
      setOnce(true);
      const jobPath = `${jobPathRoot}/${db}/job/${guid}`;
      console.log(`jobPath: ${jobPath}`);
      const res = testData || await fetch(jobPath); // if not found, return 500
      if (testData) {
        console.log(testData[guid]);
        res.ok = testData[guid];
      }
      if (!res.ok) {
        console.log('not found');
        setNotFound(true);
      }
      else {
        const jobData = testData ? testData[guid] : await res.json();
        // console.log(jobData);
        if (jobData.scheduled) {
          setScheduled(true);
        }
        // if (jobData.address) {
        //   // default textOK to true - force user to uncheck it (this is a little bit hacky)
        //   jobData.address.textOK = true;
        // }
        let phone = '801-269-0701 for Utah or 775-984-5500 for Nevada';
        if (jobData.office) {
          if (jobData.office === officeRenoId) {
            phone = '775-984-5500';
          }
          else if (jobData.office === officeSlcId) {
            phone = '801-269-0701';
          }
        }
        if (db === 'asf' || db === 'asftest') setOfficePhone(phone);
        // validatePhone(jobData);
        setCustData({...jobData.address, textOK: true});
        setDefaults(jobData.defaults);
        console.log(jobData);
      }
      setLoading(false);  
    }

    if (db && guid && jobPathRoot && !once) getData();
  }, [db, guid, setDefaults, setOfficePhone, jobPathRoot, testData, once, setOnce]);

  // eslint-disable-next-line
  useEffect(() => {
//    console.log("scrolltop");
    if (cardTop !== null) return;
    const scrolltop = document.getElementById("scrolltop");
    if (scrolltop) {
      const rect = scrolltop.getBoundingClientRect();
//      console.log(rect);
      setCardTop(rect.y);
    }
    else {
//      console.log("doh");
    }
  });

  return ( 
    loading ? <Fragment><Loader type='border' /><Row className="flex-center">Retrieving personalized data ...</Row></Fragment> :
    notFound ? <Fragment><Row className="flex-center"><p>We couldn't find information on your countertops.</p> 
      <p>For more information, please call {officePhone}.</p></Row></Fragment> :
    scheduled ? <Row className="flex-center">You're all set! We'll contact you before your template date to confirm the time window. 
    If you have questions, please call {officePhone}.</Row> :
    <Fragment>
      <WizardModal toggle={toggle} modal={modal} setModal={setModal} />
      <div id="scrolltop">
      <Card tag={Form} onSubmit={handleSubmit(onSubmitData)} className="theme-wizard">
        <CardHeader className="bg-light">
          <Nav className="justify-content-center">
            <NavItem>
              <NavLink
                className={classNames('font-weight-semi-bold', {
                  'done cursor-pointer': step > 1,
                  active: step === 1
                })}
                onClick={() => handleBackStep(1)}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">
                    <FontAwesomeIcon icon="map-marker" />
                  </span>
                </span>
                <span className="d-none d-md-block mt-1 fs--1">Confirm</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames('font-weight-semi-bold', {
                  'done  cursor-pointer': step > 2,
                  active: step === 2
                })}
                onClick={() => handleBackStep(2)}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">
                    <FontAwesomeIcon icon="check" />
                  </span>
                </span>
                <span className="d-none d-md-block mt-1 fs--1">Acknowledge</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames('font-weight-semi-bold', {
                  'done  cursor-pointer': step > 3,
                  active: step === 3
                })}
                onClick={() => handleBackStep(3)}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">
                    <FontAwesomeIcon icon="calendar" />
                  </span>
                </span>
                <span className="d-none d-md-block mt-1 fs--1">Schedule</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames('font-weight-semi-bold', {
                  'done  cursor-pointer': step > 3
                })}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">
                    <FontAwesomeIcon icon="thumbs-up" />
                  </span>
                </span>
                <span className="d-none d-md-block mt-1 fs--1">Done</span>
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody className="fs--1 font-weight-normal px-md-6 pt-4 pb-3">
          {step === 1 && <ConfirmAddress register={register} errors={errors} watch={watch} />}
          {step === 2 && <Acknowledge jobPathRoot={jobPathRoot} guid={guid} register={register} errors={errors} clearError={clearError} scroll={cardTop} />}
          {step === 3 && <ChooseDateForm register={register} errors={errors} scroll={cardTop} />}
          {step === 4 && <Success jobPathRoot={jobPathRoot} guid={guid} />}
        </CardBody>
        <CardFooter className={classNames('px-md-6 bg-light', { 'd-none': step === 4, ' d-flex': step < 4 })}>
          <ButtonIcon
            color="link"
            icon={isRTL ? 'chevron-right' : 'chevron-left'}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames('px-0 font-weight-semi-bold', { 'd-none': step === 1 })}
            onClick={() => {
              setStep(step - 1);
            }}
          >
            Prev
          </ButtonIcon>

          <ButtonIcon
            color="primary"
            className="ml-auto px-5"
            type="submit"
            icon={isRTL ? 'chevron-left' : 'chevron-right'}
            iconAlign="right"
            transform="down-1 shrink-4"
          >
            Next
          </ButtonIcon>
        </CardFooter>
      </Card>
      </div>
    </Fragment>
  );
};

export default UserForm;
